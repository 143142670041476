import Vue from 'vue';
import Service from '@/config/service-identifiers';
import $services from '@/config/container';
import router from '@/router';
import { NOTIFICATIONS_ACTIONS, NOTIFICATIONS_GETTERS } from '@/store/notifications/notifications-store-constants';
import { AccessTokenKey } from '@/store/auth/auth.store.constants';
import { getNewAuthRoute } from '@/auth/utils';

export function getDomain() {
  let domain = window.location.host.split('.').splice(-2).join('.');
  if (domain.startsWith('localhost:')) {
    domain = null;
  }

  return domain;
}

export default {
  state: {
    token: localStorage.getItem('accessToken') || null,
    user: null
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
      if (token) {
        localStorage.setItem('accessToken', token);
      } else {
        localStorage.removeItem('accessToken');
      }
    },
    SET_USER(state, user) {
      state.user = user;
    }
  },
  getters: {
    token() {
      return Vue.$cookies.get(AccessTokenKey);
    },
    isAuthenticated(_state, { token }) {
      return !!token;
    },
  },
  actions: {
    async login(_ctx, { email, password, communitySlug }) {
      const response = await $services[Service.BackendClient].post('login', {
        email,
        password,
        communitySlug,
      });
      
      const parentDomain = getDomain() ? `.${getDomain()}` : (process.env.VUE_APP_DOMAIN_1 || 'localhost');
      const date = new Date();
      date.setFullYear(date.getFullYear() + 1);
      
      Vue.$cookies.set(AccessTokenKey, response.data, {
        path: '/',
        domain: parentDomain,
        expires: date,
        secure: window.location.protocol === 'https:',
        sameSite: 'Lax'
      });
    },
    async loginsoap(_ctx, { user, password, communitySlug }) {
      const response = await $services[Service.BackendClient].post('loginsoap', {
        user,
        password,
        communitySlug,
      });
      
      const parentDomain = getDomain() ? `.${getDomain()}` : (process.env.VUE_APP_DOMAIN_1 || 'localhost');
      const date = new Date();
      date.setFullYear(date.getFullYear() + 1);
      
      Vue.$cookies.set(AccessTokenKey, response.data, {
        path: '/',
        domain: parentDomain,
        expires: date,
        secure: window.location.protocol === 'https:',
        sameSite: 'Lax'
      });
    },
    async signup(_ctx, {
      email, communitySlug, referralType, referralID,
    }) {
      const response = await $services[Service.BackendClient].post('registeremail', {
        email,
        communitySlug,
        referralType,
        referralID,
      });
      return response;
    },
    async register(_ctx, {
      email, password, name, lastname, timezone, phone, communitySlug, registrationToken, referralType, referralID, lang, country,
    }) {
      const response = await $services[Service.BackendClient].post('register', {
        email,
        password,
        name,
        lastname,
        phone,
        communitySlug,
        registrationToken,
        referralType,
        referralID,
        lang,
        country,
        timezone,
      });
      
      const parentDomain = getDomain() ? `.${getDomain()}` : (process.env.VUE_APP_DOMAIN_1 || 'localhost');
      const date = new Date();
      date.setFullYear(date.getFullYear() + 1);
      
      Vue.$cookies.set(AccessTokenKey, response.data, {
        path: '/',
        domain: parentDomain,
        expires: date,
        secure: window.location.protocol === 'https:',
        sameSite: 'Lax'
      });
      return response;
    },
    async password(_ctx, { email, communitySlug }) {
      const response = await $services[Service.BackendClient].post('password', {
        email,
        communitySlug,
      });
      return response;
    },
    async recoverPassword(_ctx, {
      password, repeatPassword, communitySlug, token,
    }) {
      const response = await $services[Service.BackendClient].post('recoverpassword', {
        password,
        repeatPassword,
        communitySlug,
        token,
      });
      return response;
    },
    async secureAuth(_ctx, { token }) {
      const response = await $services[Service.BackendClient].post('secureAuth', {
        secureToken: token,
      });
      
      const parentDomain = getDomain() ? `.${getDomain()}` : (process.env.VUE_APP_DOMAIN_1 || 'localhost');
      const date = new Date();
      date.setFullYear(date.getFullYear() + 1);
      
      Vue.$cookies.set(AccessTokenKey, response.data, {
        path: '/',
        domain: parentDomain,
        expires: date,
        secure: window.location.protocol === 'https:',
        sameSite: 'Lax'
      });
    },
    async googleAuth(_ctx, { code }) {
      const response = await $services[Service.BackendClient].post('googleauth', {
        code,
      });
      
      const parentDomain = getDomain() ? `.${getDomain()}` : (process.env.VUE_APP_DOMAIN_1 || 'localhost');
      const date = new Date();
      date.setFullYear(date.getFullYear() + 1);
      
      Vue.$cookies.set(AccessTokenKey, response.data, {
        path: '/',
        domain: parentDomain,
        expires: date,
        secure: window.location.protocol === 'https:',
        sameSite: 'Lax'
      });
    },
    async linkedinAuth(_ctx, { code }) {
      const response = await $services[Service.BackendClient].post('googleauth', {
        code,
        linkedin: true,
      });
      
      const parentDomain = getDomain() ? `.${getDomain()}` : (process.env.VUE_APP_DOMAIN_1 || 'localhost');
      const date = new Date();
      date.setFullYear(date.getFullYear() + 1);
      
      Vue.$cookies.set(AccessTokenKey, response.data, {
        path: '/',
        domain: parentDomain,
        expires: date,
        secure: window.location.protocol === 'https:',
        sameSite: 'Lax'
      });
    },
    async SSOAuth(_ctx, { code }) {
      const parentDomain = getDomain() ? `.${getDomain()}` : (process.env.VUE_APP_DOMAIN_1 || 'localhost');
      const date = new Date();
      date.setFullYear(date.getFullYear() + 1);
      
      Vue.$cookies.set(AccessTokenKey, code, {
        path: '/',
        domain: parentDomain,
        expires: date,
        secure: window.location.protocol === 'https:',
        sameSite: 'Lax'
      });
    },
    logout({ commit, rootGetters }) {
      console.log('auth.store.js - logout');
      commit('SET_TOKEN', null);
      commit('SET_USER', null);
      
      try {
        // Clear all authentication cookies
        const domain1 = process.env.VUE_APP_DOMAIN_1 || 'localhost';
        const domain2 = process.env.VUE_APP_DOMAIN_2 || 'localhost';
        const nullDomain = getDomain();
  
        // Remove cookies from all possible domains
        Vue.$cookies.remove(AccessTokenKey, '/', domain1);
        Vue.$cookies.remove(AccessTokenKey, '/', domain2);
        Vue.$cookies.remove(AccessTokenKey, '/', nullDomain);
        Vue.$cookies.remove(AccessTokenKey, '/');  // Try with no domain
        
        // Remove additional cookies
        Vue.$cookies.remove('lastUrl');  
        Vue.$cookies.remove('registeredTo');
        Vue.$cookies.remove('redirectTo');
        
        // Clear localStorage and sessionStorage
        localStorage.removeItem('accessToken');
        localStorage.removeItem('returnUrl');
        localStorage.removeItem('userData');
        
        // Determine which community to use for redirect
        const currentCommunity = rootGetters.currentCollective;
        const mainCommunity = rootGetters.mainCollective;
        const targetCommunity = mainCommunity?.key ? mainCommunity : currentCommunity;
        
        // If no community information is available, redirect to default auth
        if (!targetCommunity?.slug) {
          console.log('No community information found, redirecting to default auth');
          window.location.href = process.env.VUE_APP_MY_NECTIOS_URL;
          return Promise.resolve();
        }
        
        console.log('Logging out with community:', {
          current: currentCommunity?.slug,
          main: mainCommunity?.slug,
          target: targetCommunity.slug
        });
        
        // Handle different environments
        if (process.env.VUE_APP_ENV !== "development") {
          const authRoute = getNewAuthRoute(targetCommunity);
          console.log('Redirecting to production auth route:', authRoute);
          window.location.href = authRoute;
          return Promise.resolve();
        }
        
        // Handle Nexos specific case
        const isNexos = process.env.VUE_APP_CUSTOMER === 'nexos' || 
                      targetCommunity?.key === 'f3166db0-b635-11ef-805d-f79ce25412de';
        if (isNexos) {
          const nexosAuthUrl = process.env.VUE_APP_AUTH + '/' + targetCommunity.slug;
          console.log('Redirecting to Nexos auth:', nexosAuthUrl);
          window.location.href = nexosAuthUrl;
          return Promise.resolve();
        }
        
        // Handle default case
        const loginUrl = `${process.env.VUE_APP_COMMUNITY_URL}/${targetCommunity.slug}/auth/login`;
        console.log('Redirecting to:', loginUrl);
        window.location.href = loginUrl;
        
      } catch (error) {
        console.error('Error during logout:', error);
        // Fallback to basic logout if something goes wrong
        window.location.href = process.env.VUE_APP_MY_NECTIOS_URL;
      }
      
      return Promise.resolve();
    },
  },
};
